@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.fadein1 {
    animation: fadeInMove 0.5s ease-out forwards;
}

.fadein2 {
    animation: fadeInMove 0.5s ease-out forwards;
    animation-delay: 0.25s;
    animation-fill-mode: both;
}

.fadein3 {
    animation: fadeInMove 0.5s ease-out forwards;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}

.fadein4 {
    animation: fadeIn 0.25s ease-out forwards;
    animation-delay: 1s;
    animation-fill-mode: both;
}

.fadein5 {
    animation: fadeIn 0.25s ease-out forwards;
    animation-delay: 1.1s;
    animation-fill-mode: both;
}

@keyframes fadeInMove {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}