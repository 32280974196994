@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.splash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
}

.logo {
    margin-top: 30vh;
    width: 100%;
}

.title {
    color: white;
    font-family: 'Roboto', sans-serif;
    animation: pulseShadow 5s infinite;
}

.subtitle {
    color: white;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 100px;
    animation: pulseShadow 5s infinite;
}

.splash-button {
    width: 100%;
    max-width: 500px;
    height: 50px;
}

@keyframes pulseShadow {
    0%, 100% {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.75);
    }
    50% {
        text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.75);
    }
}
