/* STENCIL */
.floating-div {
  position: absolute;
  top: 0px; /* Distance from the top */
  right: 18px; /* Distance from the right */
  width: 190px;
  height: 50px;
  /*background-color: #2874e5;*/
  z-index: 1000; /* Ensures it stays on top */
}

/* BACKGROUND */
.floating-div2 {
  position: absolute;
  top: 8px; /* Distance from the top */
  right: 18px; /* Distance from the right */
  width: 190px;
  height: 34px;
  /* background-color: #ff151581; */
  z-index: 1000; /* Ensures it stays on top */

  justify-content: center;
  background-size: 300% 300%;
  /* background-image: linear-gradient(-45deg, #73d9bd, #29c6db 45%, #06a8f4 70%, #2488e4 100%); */
  background-image: linear-gradient(
    -45deg,
    #a3e6eb,
    #29c6db 45%,
    #06a8f4 70%,
    #a9dbe49d 100%
  );
  -webkit-animation: AnimateBG 5s ease infinite;
  animation: AnimateBG 5s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bg {
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  background-size: 300% 300%;
  /* background-image: linear-gradient(-45deg, #73d9bd, #29c6db 45%, #06a8f4 70%, #2488e4 100%); */
  background-image: linear-gradient(
    -45deg,
    #a3e6eb,
    #29c6db 45%,
    #06a8f4 70%,
    #a9dbe49d 100%
  );
  -webkit-animation: AnimateBG 5s ease infinite;
  animation: AnimateBG 5s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
