.btn-evil {
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg,
      #EF455C,
      #e1204d 45%,
      #EF455C 70%,
      #ff736e 100%);
  -webkit-animation: AnimateBG 5s ease infinite;
  animation: AnimateBG 5s ease infinite;
}

.btn-disabled {
  justify-content: center;
  background-size: 300% 300%;
  -webkit-animation: AnimateBG 5s ease infinite;
  animation: AnimateBG 5s ease infinite;
}

.btn {
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg,
      #73d9bd,
      #29c6db 45%,
      #06a8f4 70%,
      #2488e4 100%);
  -webkit-animation: AnimateBG 5s ease infinite;
  animation: AnimateBG 5s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* .btn-slider .MuiSlider-thumb:focus, 
.btn-slider .MuiSlider-thumb.Mui-focusVisible, 
.btn-slider .MuiSlider-thumb.Mui-active {
  box-shadow: none; 
} */

.btn-slider .MuiSlider-thumb:focus,
.btn-slider .MuiSlider-thumb.Mui-focusVisible,
.btn-slider .MuiSlider-thumb.Mui-active {
  box-shadow: 0px 0px 0px 8px #06a8f4;
  /* Red shadow for focus and active states */
}

.btn-slider .MuiSlider-track {
  background: linear-gradient(-45deg,
      #73d9bd,
      #29c6db 45%,
      #06a8f4 70%,
      #2488e4 100%);
  animation: AnimateBG 5s ease infinite;
}

.btn-slider .MuiSlider-thumb {
  background-color: #ffffff;
  /* Example fallback color, adjust as needed */
  /* Apply other styles as needed */
}

.btn-slider .MuiSlider-rail {
  background-color: rgba(0, 0, 0, 0.13);
  /* Adjust as needed */
  opacity: 0.5;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}